import logo from './logo.svg';
import './App.css';
import Home from "./pages/home/index"
import { createTheme, ThemeProvider, CssBaseline, GlobalStyles } from "@mui/material";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Privacy from './pages/privacy';
import TrackTrain from './pages/Tracking/index';
import { ToasterContainer } from './components/toaster/Toaster';



// function App() {
//   return (

//     <ThemeProvider theme={theme}>
//     <CssBaseline />
//     <GlobalStyles
//       styles={{
//         body: {
//           fontFamily: "Poppins, Arial, sans-serif",
//         },
//       }}
//     />
//     {/* Your App Content */}
//     <div className="App">
//       <Home />
//     </div>
//   </ThemeProvider>

//   );
// }

// export default App;



const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif", // Set Poppins as default
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToasterContainer />
      <GlobalStyles
        styles={{
          body: {
            fontFamily: "Poppins, Arial, sans-serif",
          },
        }}
      />
      {/* Your App Content */}
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/track-train" element={<TrackTrain />} />

          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
