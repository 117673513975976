import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Box
            sx={{
                mt: 4,
                py: 12,
                px: 3,
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
            }}
        >
            {/* Single Row */}
            <Grid container spacing={3} justifyContent="center" alignItems="center" display="flex">
                <Grid item xs={6}>
                    <Link to="/track-train" style={{ textDecoration: 'none', color: 'white', marginRight: '20px' }}>Track My Train</Link>
                </Grid>

                <Grid item xs={6}>
                    {/* Bottom Section */}
                    {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}> */}

                        <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</Link>

                    {/* </Box> */}
                </Grid>
            </Grid>
            <Typography sx={{paddingTop:"60px"}}>
            Download Track My Train App Now and Make Your Journey Effortless!
            </Typography>
        </Box>
    );
};

export default Footer;
