import { Close } from '@mui/icons-material';
import { Box, CardMedia, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';

function SimpleDialog({ open, title, children, onClose, loading }) {
  return (
    <Dialog
      maxWidth="md"
      open={open}
      // onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: "30px", background:"white", position: "relative" } }}
    >
      <Box sx={{py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 }, }}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2
          }}
        >
          <Typography variant={"subtitle1"} sx={{ color: "darkgreen"}}>{title}</Typography>
        </DialogTitle>
          {children}
      </Box>
      {/* <IconButton
        sx={{
          display: open ? "flex" : "none",
          background: "darkgreen",
          position: "absolute",
          top: 24,
          right: 24,
          width: "34px",
          height: "34px",
          
        }}
        onClick={() => onClose()}
      >
        <Close sx={{ color: "white" }} />
      </IconButton> */}
    </Dialog>
  )
}

export default SimpleDialog