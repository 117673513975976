import { post, get, put, deleted, patch } from "../index";
import AuthRoutes from "./routes";

const AuthServices = {
  login: async (obj) => {
    const result = await post(AuthRoutes.login, obj);
    return result;
  },
  getTrains: async () => {
    const result = await get(AuthRoutes.getTrains);
    return result;
  },
  getTrainsById: async (id) => {
    const result = await get(AuthRoutes.getTrainsById + `?id=${id}`);
    return result;
  },
  getAllLocomotive: async () => {
    const result = await get(AuthRoutes.getAllLocomotive);
    return result;
  },
};

export default AuthServices;
